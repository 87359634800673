export const tableRecordList3 = [
  {
    title: "回收时间",
    width: "30%",
    index: "updated_at",
    center: "center",
    render: true,
  },
  {
    title: "物品",
    width: "50%",
    index: "code",
    center: "center",
    render: true,
  },
  // {
  //   title: "详细",
  //   width: "25%",
  //   index: "status_alias",
  //   center: "center",
  // },
  // {
  //   title: "回购价",
  //   width: "15%",
  //   index: "bean",
  //   center: "center",
  // },
];
export const tableRecordList2 = [
  {
    title: "提货发起时间",
    width: "40%",
    index: "updated_at",
    center: "center",
    render: true,
  },
  {
    title: "物品",
    width: "35%",
    index: "name",
    center: "center",
    render: true,
  },
  {
    title: "状态",
    width: "25%",
    index: "status_alias",
    center: "center",
    render: true,
  },
  // {
  //   title: "回购价",
  //   width: "20%",
  //   index: "bean",
  //   center: "center",
  // },
];
export const tableRecordList4 = [
  {
    title: "交易时间",
    width: "30%",
    index: "created_at",
    center: "center",
    render: true,
  },
  {
    title: "交易类型",
    width: "25%",
    index: "change_type_alias",
    center: "center",
  },
  // {
  //   title: "订单号",
  //   width: "30%",
  //   index: "orderIo",
  //   center: "center",
  // },
  {
    title: "变动金额",
    width: "25%",
    index: "bean",
    center: "center",
  },
  {
    title: "变动余额",
    width: "25%",
    index: "final_bean",
    center: "center",
  },
];
// 弹药
export const tableRecordList8 = [
  {
    title: "交易时间",
    width: "25%",
    index: "created_at",
    center: "center",
  },
  // {
  //   title: "交易类型",
  //   width: "15%",
  //   index: "type",
  //   center: "center",
  //   render: true
  // },
  {
    title: "开箱投入",
    width: "25%",
    index: "names",
    center: "center",
    render: true,
  },
  {
    title: "开箱获得",
    width: "25%",
    index: "box_record_names",
    center: "center",
    render: true,
  },
  {
    title: "赠送物品",
    width: "25%",
    index: "award_name",
    center: "center",
    render: true,
  },
];
// 熔炼
export const tableRecordList9 = [
  {
    title: "交易时间",
    width: "20%",
    index: "created_at",
    center: "center",
  },
  // {
  //   title: "交易类型",
  //   width: "15%",
  //   index: "type",
  //   center: "center",
  //   render: true
  // },
  {
    title: "熔炼投入",
    width: "40%",
    index: "names",
    center: "center",
    render: true,
  },
  {
    title: "熔炼获得",
    width: "40%",
    index: "award_name",
    center: "center",
  },
];

// 弹药
export const tableRecordList10 = [
  {
    title: "交易时间",
    width: "25%",
    index: "created_at",
    center: "center",
  },
  {
    title: "交易类型",
    width: "15%",
    index: "change_type_alias",
    center: "center",
    // render: true,
  },
  {
    title: "积分变动",
    width: "25%",
    index: "bean",
    center: "center",
    // render: true,
  },
  {
    title: "积分余额",
    width: "25%",
    index: "final_bean",
    center: "center",
    // render: true,
  },
];

export const tableData = {
  total: 15,
  pageSize: 15,
  list: [{}],
};
export const acceptData = {
  total: 0,
  pager: 0,
  list: [{}],
};

export const newlistData = {
  total: 0,
  pageSize: 0,
  current_page: 1,
  list: [{}],
};
