<template>
  <div id="Warehouse">
    <div class="bankbox">
      <img src="../../assets/images/new/back-icon.png" alt="" @click="bankgo" />
      <p class="bankname">我的背包</p>
    </div>
    <div class="Warehouse-box2">
      <div
        class="typebox"
        :class="{ 'typebox80': [3, 2, 6].includes(actypeindex) }">
        <div
          class="typeitem point"
          :class="{ actype: actypeindex === item.id }"
          @click="typechange(item.id)"
          v-for="item in typedata"
          :key="item.id">
          {{ item.title }}
        </div>
        <div v-if="actypeindex === 3" class="pullbox">
          <div class="selectbox">
            <div class="select-box point" @click="isShowFloat = !isShowFloat">
              <div class="input-value flex-s">
                {{ parseSelectList.find((item) => item.value == value)?.label }}
              </div>
              <div class="row">
                <img
                  src="../../assets/images/newuser/mb-rechange-pullicon.png"
                  alt="" />
              </div>
              <div v-show="isShowFloat" class="float-box">
                <div
                  @click="changeSelect(item)"
                  class="float-box-item flex-s"
                  v-for="(item, index) in parseSelectList"
                  :key="index">
                  {{ item.label }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 钱包 -->
        <div v-if="actypeindex === 2" class="pullbox">
          <div class="selectbox">
            <div class="select-box point" @click="pisShowFloat = !pisShowFloat">
              <div class="input-value flex-s">
                {{ SelectList.find((item) => item.pvalue == pvalue)?.label }}
              </div>
              <div class="row">
                <img
                  src="../../assets/images/newuser/mb-rechange-pullicon.png"
                  alt="" />
              </div>
              <div v-show="pisShowFloat" class="float-box">
                <div
                  @click="pricechangeSelect(item)"
                  class="float-box-item flex-s"
                  v-for="(item, index) in SelectList"
                  :key="index">
                  {{ item.label }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 钱包 -->
        <div v-if="actypeindex === 6" class="pullbox">
          <div class="selectbox">
            <div class="select-box point" @click="pisShowFloat = !pisShowFloat">
              <div class="input-value flex-s">
                {{
                  jifenSelectList.find((item) => item.value == pvalue)?.label
                }}
              </div>
              <div class="row">
                <img
                  src="../../assets/images/newuser/mb-rechange-pullicon.png"
                  alt="" />
              </div>
              <div v-show="pisShowFloat" class="float-box">
                <div
                  @click="jifenchangeSelect(item)"
                  class="float-box-item flex-s"
                  v-for="(item, index) in jifenSelectList"
                  :key="index">
                  {{ item.label }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="box-show-wrap">
        <div class="bag-list-box" v-if="actypeindex == 1">
          <div class="clickbox">
            <div class="lbox">
              <div class="clickall" @click="SelectAll">
                <img
                  v-show="!IS_Select_All"
                  src="@/assets/images/about/bag/mb-check.png"
                  alt=""
                  style="width: 100%; height: 0.12rem;" />
                <img
                  v-show="IS_Select_All"
                  src="@/assets/images/about/bag/mb-checked.png"
                  alt=""
                  style="width: 100%; height: 0.12rem;" />
                <p>全选</p>
              </div>
              <div class="checktext">
                <p>
                  已选
                  <span style="color: yellow">{{ chooseIdList.length }}</span>
                  件
                </p>
              </div>
            </div>
            <div class="rbox">
              <div class="btn1 point" @click="WholeExtract">提货</div>
              <div class="btn2 point" @click="$router.push('/fusion')">
                熔炼
              </div>
            </div>
          </div>

          <div class="Warehouse-List" v-show="loadingShow == false">
            <div style="font-size: 0.1rem; display: flex; align-items: center">
              <img
                style="width: 0.12rem; margin-right: 0.1rem"
                src="../../assets//images//newuser/mb-user-gantan.png"
                alt="" />
              若频繁取消报价，用户将受到一段时间内禁止提货的处罚
            </div>
            <div
              class="Warehouse-List-item"
              v-for="(item, index) in WarehouseLists"
              :key="index"
              :class="{
                'item-ac': acShop.indexOf(item) != -1,
                'Warehouse-List-item-active': chooseIdList.includes(item.id),
              }"
              ref="Select"
              @click="itemOnClick(item, index)">
              <div class="MBX">
                <div class="span" v-show="item.dura_alias != '无'">
                  {{ item.dura_alias }}
                </div>
              </div>
              <div class="pic">
                <img v-lazy="item.cover" alt="" />
              </div>
              <div class="namebox">{{ item.name }}</div>
              <div>
                <!-- <Cprice :size="0.1" :price="item.bean" /> -->
              </div>
            </div>
          </div>
        </div>
        <div class="pagination" v-if="actypeindex == 1">
          <Pagination
            v-show="PageNum > 0"
            :total="PageNum"
            :page.sync="page_Num"
            :limit.sync="per_page"
            @pagination="handleCurrentChange" />
        </div>
        <div class="record-list-box" v-show="actypeindex == 2">
          <record
            :tableRecordList="tableRecordList4"
            :tableData="tableData"
            @pageChange="priceChange">
            <template slot-scope="item">
              <div v-if="item.item === 'created_at'">
                <div style="line-height: 0.2rem">
                  {{ item.data.created_at?.substring(0, 16) }}
                </div>
              </div>
            </template>
          </record>
        </div>
        <!-- 弹药记录 -->
        <div class="record-list-box" v-if="actypeindex === 5">
          <record
            :tableRecordList="tableRecordList8"
            :tableData="newlistData"
            renderSearch
            @pageChange="danyaochange">
            <template slot-scope="item">
              <div
                v-if="item.item == 'names'"
                class="point"
                @click="showlistname(item.data.names), typetitle(1)">
                <div
                  v-if="item.data.names && item.data.names.length"
                  class="listitem">
                  {{ item.data.names[0] }}
                </div>
              </div>
              <div
                v-if="item.item == 'box_record_names'"
                class="point"
                @click="showlistname(item.data.box_record_names), typetitle(2)">
                <div
                  v-if="
                    item.data.box_record_names &&
                    item.data.box_record_names.length
                  "
                  class="listitem">
                  {{ item.data.box_record_names[0] }}
                </div>
              </div>
              <div
                v-if="item.item == 'award_name'"
                @click="showlistname(item.data.award_name), typetitle(4)">
                <div class="listitem">
                  {{ item.data.award_name }}
                </div>
              </div>
            </template>
          </record>
        </div>
        <!-- 积分记录 -->
        <div class="record-list-box" v-if="actypeindex === 6">
          <record
            :tableRecordList="tableRecordList10"
            :tableData="newlistData"
            renderSearch
            @pageChange="jiFenChangeOnClick">
          </record>
        </div>
        <!-- 熔炼记录 -->
        <div class="record-list-box" v-if="actypeindex === 4">
          <record
            :tableRecordList="tableRecordList9"
            :tableData="newlistData"
            renderSearch
            @pageChange="ronglianchange">
            <template slot-scope="item">
              <div
                v-if="item.item == 'names'"
                class="point"
                @click="showlistname(item.data.names), typetitle(3)">
                <div
                  v-if="item.data.names && item.data.names.length"
                  class="listitem">
                  {{ item.data.names[0] }}
                </div>
              </div>
            </template>
          </record>
        </div>

        <div class="record-list-box" v-show="actypeindex == 3">
          <record
            :tableRecordList="tableRecordList2"
            :tableData="acceptData"
            renderSearch
            @pageChange="PickChange">
            <template slot-scope="item">
              <div v-if="item.item === 'updated_at'">
                <div style="line-height: 0.2rem">
                  {{ item.data.updated_at?.substring(0, 16) }}
                </div>
              </div>
              <div v-if="item.item === 'name'">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  ">
                  <img
                    style="display: block; width: 60%"
                    :src="item.data.cover"
                    alt="" />
                  <!-- <span style="
                      width: 70%;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      white-space: nowrap;
                    ">{{ item.data.name }}</span> -->
                </div>
              </div>
              <div v-if="item.item === 'status_alias'">
                <div v-if="item.data.back_message != ''">
                  ({{ item.data.back_message }}) {{ item.data.status_alias }}
                </div>
                <div v-else>
                  {{ item.data.status_alias }}
                </div>
              </div>
            </template>
          </record>
        </div>
      </div>
    </div>
    <!-- 绑定steam -->
    <van-overlay
      :lock-scroll="false"
      @mousewheel.prevent.native="() => {}"
      :show="showlistbox">
      <div class="showbox">
        <div class="itembox">
          <div
            class="title"
            @click="
              () => {
                this.showlistbox = false;
              }
            ">
            {{ this.typetext }}
            <img src="../../assets/images/new/bg7.png" alt="" />
          </div>
          <div class="line"></div>
          <div class="content" v-if="this.typetext != '开箱赠送'">
            <div class="namesbox" v-for="(item, i) in showlistinfo" :key="i">
              {{ item }}
            </div>
          </div>
          <div class="content" v-else>
            <div class="namesbox">
              {{ showlistinfo }}
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import {
  Storage,
  Extract,
  PersonalInfo,
  Recharge_record,
  getsynthesisData,
  getammunitionData,
  getJiFenDataApi,
} from "@/network/api.js";
import { mapMutations } from "vuex";
import record from "../../components/newtable/index.vue";
import {
  tableData,
  tableRecordList3,
  tableRecordList2,
  tableRecordList4,
  tableRecordList8,
  tableRecordList9,
  tableRecordList10,
  acceptData,
  newlistData,
} from "./index.js";
export default {
  name: "",
  components: {
    record,
  },
  data() {
    return {
      typedata: [
        {
          id: 1,
          title: "我的背包",
        },
        {
          id: 2,
          title: "钱包记录",
        },
        {
          id: 6,
          title: "积分记录",
        },
        {
          id: 3,
          title: "提货记录",
        },
        {
          id: 4,
          title: "熔炼记录",
        },
        {
          id: 5,
          title: "弹药记录",
        },
      ],
      actypeindex: 1,
      tableData,
      tableRecordList3,
      tableRecordList2,
      tableRecordList4,
      tableRecordList8,
      tableRecordList9,
      tableRecordList10,
      newlistData,
      acceptData,
      dropList: [
        {
          name: "按获得时间排序",
          id: 1,
        },
        {
          name: "按金额排序",
          id: 2,
        },
      ],
      optionIndex: 0,
      WarehouseLists: [],
      AllWarehouseObj: {},
      loadingShow: true,
      PageNum: 0,
      // Select_All: false,
      GiveShow: false,
      GiveImg: null,
      GiveId: "",
      Invitation_code: "",
      per_page: 10,
      page_Num: 1,

      // TakeValue: 4,
      isShowFloat: false, //下拉状态
      pisShowFloat: false, //钱包下拉
      selectList: [
        {
          value: "1",
          label: "正在发货",
        },
        {
          value: "2",
          label: "提货完成",
        },
      ],
      value: "1",
      SwitchState: true,

      //提货
      TakingLists: [],
      TakingListPageNum: 0,
      TakingListper_page: 0,

      PickSwitch: 1,

      PickCode: 9, //提货状态相关
      PickShow: true,
      bean: 0,
      num: 0,
      acShop: [], //选中的数组
      chooseIdList: [],
      sort: 0, //0= 时间排序  1 按照价格排序

      //钱包记录下拉
      priceselectList: [
        {
          label: "全部",
          pvalue: "0",
        },
        {
          label: "开箱",
          pvalue: "1",
        },
        {
          label: "装备回收",
          pvalue: "4",
        },
        {
          label: "充值",
          pvalue: "5",
        },
        {
          label: "首充奖励",
          pvalue: "6",
        },
        {
          label: "转盘奖励",
          pvalue: "33",
        },
      ],
      //钱包记录下拉
      jifenselectList: [
        {
          label: "全部",
          value: "0",
        },
        {
          label: "开箱",
          value: "1",
        },
        {
          label: "充值",
          value: "2",
        },
      ],
      pvalue: "0",
      curtype: null,
      aclistindex: null, //选中下标
      user_id: "",
      page_num: 1,
      showlistbox: false,
      showlistinfo: [],
      typetext: "",
      jiFenType: 1,
    };
  },
  computed: {
    SelectList() {
      const array = this.priceselectList.map((item) => {
        return {
          label: item?.label || item,
          pvalue: item?.pvalue || item,
        };
      });
      return array;
    },
    parseSelectList() {
      const array = this.selectList.map((item) => {
        return {
          label: item?.label || item,
          value: item?.value || item,
        };
      });
      return array;
    },
    jifenSelectList() {
      const array = this.jifenselectList.map((item) => {
        return {
          label: item?.label || item,
          value: item?.value || item,
        };
      });
      return array;
    },
    allPrice() {
      let price = 0;
      this.chooseIdList.forEach((item) => {
        price = price * 1 + this.AllWarehouseObj[item + ""].bean * 1;
      });
      return price;
    },
    WarehouseIdLists() {
      const arr = this.WarehouseLists.map((item) => {
        return item.id;
      });
      return arr;
    },
    IS_Select_All() {
      let flag = true;
      this.WarehouseIdLists.forEach((item) => {
        if (!this.chooseIdList.includes(item)) {
          flag = false;
        }
      });
      return flag;
    },
  },
  created() {
    this.GetStorage(1);
    // this.GetTakingList(1, 0, this.PickCode);
  },

  methods: {
    // 展示标题
    typetitle(v) {
      if (v == 1) {
        this.typetext = "开箱投入";
      } else if (v == 2) {
        this.typetext = "开箱获得";
      } else if (v == 3) {
        this.typetext = "熔炼投入";
      } else if (v == 4) {
        this.typetext = "开箱赠送";
      }
    },
    //熔炼分页
    ronglianchange(v) {
      this.page_num = v.page;
      this.getronliandata();
    },
    // 弹药分页
    danyaochange(v) {
      this.page_num = v.page;
      this.getdanyaodata();
    },
    async getronliandata() {
      let datas = JSON.parse(localStorage.getItem("boxUserInfo"));
      this.user_id = datas.id;
      let params = {
        page: this.page_num,
        pagesize: 21,
        user_id: this.user_id,
      };
      const res = await getsynthesisData(params);
      let data = res.data.data.data;
      this.newlistData = {
        total: res.data.data.total,
        pageSize: Number(res.data.data.per_page),
        current_page: res.data.data.current_page,
        list: data.map((item) => {
          return {
            ...item,
            height: "0.4rem",
          };
        }),
      };
    },
    async getdanyaodata() {
      let datas = JSON.parse(localStorage.getItem("boxUserInfo"));
      this.user_id = datas.id;
      let params = {
        page: this.page_num,
        pagesize: 21,
        user_id: this.user_id,
      };
      const res = await getammunitionData(params);
      let data = res.data.data.data;
      this.newlistData = {
        total: res.data.data.total,
        pageSize: Number(res.data.data.per_page),
        current_page: res.data.data.current_page,
        list: data.map((item) => {
          return {
            ...item,
            height: "0.4rem",
          };
        }),
      };
    },

    // 积分分页
    jiFenChangeOnClick(v) {
      this.page_num = v.page;
      this.getJiFenDataFun();
    },
    // 获取积分数据
    async getJiFenDataFun() {
      let datas = JSON.parse(localStorage.getItem("boxUserInfo"));
      this.user_id = datas.id;
      let params = {
        page: this.page_num,
        pagesize: 21,
        user_id: this.user_id,
        type: this.jiFenType,
      };
      const res = await getJiFenDataApi(params);
      console.log("积分列表请求结果", res);
      let data = res.data.data.data;
      this.newlistData = {
        total: res.data.data.total,
        pageSize: Number(res.data.data.per_page),
        current_page: res.data.data.current_page,
        list: data.map((item) => {
          return {
            ...item,
            height: "0.4rem",
          };
        }),
      };
    },

    closeindex() {
      this.aclistindex = "";
    },
    showlistname(v) {
      this.showlistinfo = v;
      this.showlistbox = true;
    },
    pricechangeSelect(v) {
      this.pvalue = v.pvalue;
      this.curtype = v.pvalue;
      if (v.value == 0) {
        this.curtype = null;
      }
      this.getrecord(this.curtype);
    },
    jifenchangeSelect(v) {
      this.pvalue = v.value;
      this.jiFenType = v.value;
      if (v.value == 0) {
        this.jiFenType = null;
      }
      this.getJiFenDataFun();
    },
    changeSelect(v) {
      this.value = v.value;
      if (v.value == "2") {
        this.PickCode = 1;
        this.GetTakingList(1, this.PickCode);
      }
      if (v.value == "1") {
        this.PickCode = 9;
        this.GetTakingList(1, this.PickCode);
      }
    },
    bankgo() {
     this.$router.back();
    },
    typechange(v) {
      console.log(12345, v);
      this.actypeindex = v;
      if (v === 2) {
        this.getrecord(this.curtype);
      } else if (v === 3) {
        this.GetTakingList(1, this.PickCode);
      } else if (v === 5) {
        this.page_num = 1;
        this.getdanyaodata();
      } else if (v === 4) {
        this.page_num = 1;
        this.getronliandata();
      } else if (v === 6) {
        this.page_num = 1;
        this.getJiFenDataFun();
      }
      setTimeout(() => {
        if (v === 1 || v === 2) {
          document.querySelectorAll(".typebox")[0].scrollLeft = 0;
        } else {
          document.querySelectorAll(".typebox")[0].scrollLeft =
            document.querySelectorAll(".typebox")[0].scrollWidth;
        }
      }, 0);
    },
    // 取回记录分页
    retrievepage(val) {
      this.getdata(val.page);
    },
    // 排序
    dustore(e) {
      this.page_Num = 1;
      if (e.name == "按获得时间排序") {
        console.log(111);
        this.sort = 0;
        Storage(this.page_Num, this.sort, 0).then((res) => {
          if (res.data.data.data.length > 0) {
            this.WarehouseLists = res.data.data.data;
            this.PageNum = Number(res.data.data.total);
            this.per_page = Number(res.data.data.per_page);
          }
        });
      } else if (e.name == "按金额排序") {
        console.log(222);
        this.sort = 1;
        Storage(this.page_Num, this.sort, 0).then((res) => {
          if (res.data.data.data.length > 0) {
            this.WarehouseLists = res.data.data.data;
            this.PageNum = Number(res.data.data.total);
            this.per_page = Number(res.data.data.per_page);
          }
        });
      }
    },
    // optionChange(i) {
    //   this.optionIndex = i;
    //   this.sort = 0;
    //   if (i == 1) {
    //     this.getdata(1);
    //   } else if (i == 2) {
    //     this.GetTakingList(1, 4);
    //   }
    // },
    // 获取到最新的钱包记录
    getrecord(num, page) {
      Recharge_record(num, page).then((res) => {
        this.tableData.list = res.data.data.data;
        this.tableData.total = res.data.data.total;
        this.tableData.pageSize = res.data.data.per_page;
        this.tableData.current_page = res.data.data.current_page;
      });
    },
    // // 获取取回记录数据
    // getdata(num) {
    //   Storage(num, this.sort, 2).then((res) => {
    //     this.tableData.total = Number(res.data.data.total);
    //     this.tableData.pageSize = Number(res.data.data.per_page);
    //     this.tableData.list = res.data.data.data;
    //   });
    // },
    //仓库与提货列表选项卡
    // GetGoodsList() {
    //   this.loadingShow = true;
    //   this.SwitchState = true;
    //   this.GetStorage(1);
    // },

    // GetExtractList() {
    //   this.SwitchState = false;
    //   this.PickSwitch = 4;
    //   this.GetTakingList(1, 4);
    // },

    //提货过程列表
    PickTHZ(num) {
      // this.PickShow = true;
      // this.PickSwitch = num;
      this.PickCode = num.id;
      this.GetTakingList(1, this.PickCode);
    },

    // 提货列表
    GetTakingList(num, code) {
      Storage(num, 0, code).then((res) => {
        this.acceptData.total = res.data.data.total;
        this.acceptData.pager = res.data.data.per_page;
        this.acceptData.list = res.data.data.data;
      });
    },

    //提货列表分页
    PickChange(val) {
      this.GetTakingList(val.page, this.PickCode);
    },
    priceChange(v) {
      this.page_num = v.page;
      this.getrecord(this.pvalue, this.page_num);
    },
    //获取仓库列表(第一页)
    GetStorage(num) {
      this.loadingShow = true;
      Storage(num, this.sort).then((res) => {
        if (res.data.data.data.length > 0) {
          this.WarehouseLists = res.data.data.data;
          this.PageNum = Number(res.data.data.total);
          this.per_page = Number(res.data.data.per_page);
        } else {
          if (num == "1") {
            this.WarehouseLists = res.data.data.data;
            this.PageNum = Number(res.data.data.total);
            this.per_page = Number(res.data.data.per_page);
            this.loadingShow = false;
          } else {
            this.GetStorage("1");
          }
        }
      });
    },

    // 仓库列表分页
    handleCurrentChange(val) {
      this.GetStorage(val.page);
      this.page_Num = val.page;
    },

    //多选提取
    WholeExtract() {
      // let arr = this.WarehouseLists.filter((item) => {
      //   return item.Select == true;
      // });
      // let data = [];
      // for (let i = 0; i < arr.length; i++) {
      //   data.push(arr[i].id);
      // }
      let data = this.chooseIdList;
      Extract(data).then((res) => {
        if (res.data.code == 200) {
          this.num = 0;
          this.bean = 0;
          this.$message({
            message: res.data.message,
            type: "success",
          });
          this.GetPersonalInfo();
          this.GetStorage(this.page_Num);
          // this.Select_All = false;
        } else {
          this.$message({
            message: res.data.message,
            type: "error",
          });
        }
      });
    },

    //选中
    itemOnClick(item, index) {
      if (this.chooseIdList.includes(item.id)) {
        this.chooseIdList.splice(this.chooseIdList.indexOf(item.id), 1);
        this.bean -= item.bean * 1;
        this.num -= 1;
      } else {
        this.bean += item.bean * 1;
        this.num += 1;
        this.chooseIdList.push(item.id);
      }
      // 选中的背景
      // if (this.acShop.indexOf(item) !== -1) {
      //   this.acShop.splice(this.acShop.indexOf(item), 1)//取消
      // } else {
      //   this.acShop.push(item)
      // }
      // 数据保存
      // if (item.Select) {
      //   this.bean -= item.bean * 1;
      //   this.num -= 1;
      //   // delete item.Select;
      //   // this.$refs.Select[index].style = "background: url(img/bg1.ae428dda.png) no-repeat center center; background-size: 133% auto;";
      // } else {
      //   this.bean += item.bean * 1;
      //   this.num += 1;
      //   // let key = "Select";
      //   // let value = true;
      //   // item[key] = value;
      //   // this.$refs.Select[index].style = "background: url(img/bg2.f8c8d509.png) no-repeat center center;  background-size: 133% auto;";
      // }
    },

    //全选
    SelectAll(v) {
      if (this.IS_Select_All == false) {
        const array = [
          ...new Set([...this.chooseIdList, ...this.WarehouseIdLists]),
        ];
        this.chooseIdList = array;
        // for (let i = 0; i < this.WarehouseLists.length; i++) {
        //   let key = "Select";
        //   let value = true;
        //   this.WarehouseLists[i][key] = value;
        //   this.$refs.Select[i].style = "background: url(img/bg2.f8c8d509.png) no-repeat center center;  background-size: 133% auto;";
        //   this.bean += this.WarehouseLists[i].bean * 1;
        // }
        // this.num = this.WarehouseLists.length;
        // this.Select_All = true;
        // this.acShop = []
      } else {
        // this.chooseIdList = this.chooseIdList.splice()
        this.chooseIdList = this.chooseIdList.filter((item) => {
          return !this.WarehouseIdLists.includes(item);
        });
        // this.chooseIdList = []
        // for (let i = 0; i < this.WarehouseLists.length; i++) {
        //   delete this.WarehouseLists[i].Select;
        //   this.$refs.Select[i].style = "background: url(img/bg1.ae428dda.png) no-repeat center center; background-size: 133% auto;";
        // }
        // this.bean = 0;
        // this.num = 0;
        // this.Select_All = false;
      }
    },

    //个人信息
    GetPersonalInfo() {
      PersonalInfo().then((res) => {
        this.User(res.data.data);
      });
    },

    ...mapMutations(["User"]),
  },

  watch: {
    WarehouseLists(val) {
      // for (let i = 0; i < this.WarehouseLists.length; i++) {
      //   delete this.WarehouseLists[i].Select;
      //   setTimeout(() => {
      //     if (this.$refs.Select) {
      //       // this.$refs.Select[i].style = "background: url('../../assets/images/mine/bg1.png') no-repeat center center; background-size: 125% auto;";
      //     }
      //     this.loadingShow = false;
      //   }, 100);
      // }
      // this.Select_All = true;
      setTimeout(() => {
        this.loadingShow = false;
      }, 100);
      val.forEach((item) => {
        this.AllWarehouseObj[item.id + ""] = item;
        if (!this.chooseIdList.includes(item.id)) {
          // this.Select_All = false;
        }
      });
      // this.Select_All = false;
    },

    TakingLists(val) {
      this.PickShow = false;
    },

    // TakeValue(v) {
    //   this.PickCode = String(v);
    //   this.GetTakingList(1, String(v));
    // },
  },
};
</script>

<style scoped lang="scss">
.GoodsList-title {
  padding-bottom: 20px;
  border-bottom: 1px solid #232749;
  margin-bottom: 20px;
}
#Warehouse{
  height: 11rem;
}
.bankbox {
  width: 3.35rem;
  margin: 0 auto;
  height: 0.44rem;
  display: flex;
  align-items: center;
  background: #020715;
  text-align: center;
  position: relative;
  img {
    position: absolute;
    width: 0.25rem;
    height: 0.14rem;
  }
  .bankname {
    width: 100%;
    text-align: center;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 0.16rem;
    color: #ffffff;
  }
}
.Warehouse-box2 {
  width: 100%;
  padding: 0 0.18rem;
  box-sizing: border-box;
  position: relative;
  .goodsimg {
    width: 0.64rem;
    height: 0.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.07rem;
    img {
      width: 100%;
    }
  }
  .typebox {
    width: 100%;
    display: flex;
    align-items: center;
    // position: relative;
    overflow-x: auto;
    align-content: center;
    // margin-bottom: 2rem;
    .typeitem {
      padding: 0.1rem 0.025rem;
      font-size: 0.12rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #636363;
      flex-shrink: 0;
    }
    .actype {
      color: #c5bbed;
    }
  }
  .typebox80 {
    width: 80%;
  }
  .pullbox {
    position: absolute;
    right: 0.18rem;
    top: 0.05rem;
    .selectbox {
      .select-box {
        width: 0.6rem;
        height: 0.22rem;
        background: #08080f;
        border: 0.01rem solid #2f2f5e;
        position: relative;
        .input-value {
          width: 100%;
          height: 100%;
          line-height: 0.22rem;
          padding-left: 0.02rem;
          box-sizing: border-box;
          font-size: 0.1rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #c5bbed;
          overflow: hidden;
          white-space: nowrap;
        }
        .row {
          position: absolute;
          top: 0.02rem;
          right: 0.12rem;
          z-index: 1;
          img {
            width: 0.17rem;
            height: 0.16rem;
          }
        }

        .float-box {
          position: absolute;
          left: -0.01rem;
          background: #08080f;
          z-index: 99;
          border: 0.01rem solid #2f2f5e;
          border-top: 0;
          .float-box-item {
            width: 0.58rem;
            height: 0.22rem;
            line-height: 0.22rem;
            padding: 0 0.02rem;
            box-sizing: border-box;
            font-size: 0.1rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #c5bbed;
            white-space: nowrap;
            overflow: hidden;
          }
          .float-box-item:hover {
            background: #2f2f5e;
            color: #c5bbed;
          }
        }
      }
    }
  }

  .box-show-wrap {
    width: 3.35rem;
    margin: 0 auto;
    .record-list-box {
      padding-bottom: 0.2rem;
      overflow-y: scroll;
    }
    .bag-list-box {
      width: 100%;
      height: 9.3rem;
      background: #020715;
      border: 0.02rem solid #2f2f5e;
      margin-bottom: 0.18rem;
      .clickbox {
        width: 100%;
        height: 0.4rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0.1rem;
        .lbox {
          display: flex;
          align-items: center;
          .clickall {
            display: flex;
            align-items: center;
            white-space: nowrap;
            img {
              width: 0.12rem;
              height: 0.12rem;
              margin-right: 0.1rem;
            }
            p {
              font-size: 0.12rem;
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              color: #8a8ace;
            }
          }
          .checktext {
            display: flex;
            margin-left: 0.05rem;
            white-space: nowrap;
            align-items: center;
            p {
              font-size: 0.1rem;
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              margin-right: 0.05rem;
            }
          }
        }
        .rbox {
          width: 1.05rem;
          display: flex;
          justify-content: space-between;
          .btn1 {
            width: 0.49rem;
            height: 0.2rem;
            text-align: center;
            line-height: 0.2rem;
            font-size: 0.1rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            background: #e55325;
            border-radius: 0.02rem;
            border: 0.01rem solid #e69379;
          }
          .btn2 {
            width: 0.49rem;
            height: 0.2rem;
            text-align: center;
            line-height: 0.2rem;
            font-size: 0.1rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            background: #5638cc;
            border-radius: 0.02rem;
            border: 0.01rem solid #8684ff;
          }
        }
      }
    }
  }

  .Warehouse-List {
    width: 3.12rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto;
    // height: 6.2rem;
    overflow-y: scroll;
    padding: 0.1rem 0;
    .Warehouse-List-item {
      background: url("../../assets/images/mobile/m-mine-item-bg11.png")
        no-repeat center center;
      width: 1rem;
      height: 1.02rem;
      background-size: 100% 100%;
      overflow: hidden;
      color: #ccc;
      margin: 0.06rem 0.025rem 0 0.015rem;
      font-size: 0.12rem;
      cursor: pointer;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      .MBX {
        width: 100%;
        min-height: 0.1rem;
        padding: 0.04rem 0.02rem;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;

        .span {
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 0.1rem;
          color: #adadad;
        }
      }

      .pic {
        width: 0.67rem;
        height: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 100%;
        }
      }

      .namebox {
        text-align: center;
        width: 100%;
        padding: 0 0.1rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 0.1rem;
        color: #ffffff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .Warehouse-List-item-active {
      background: url("../../assets/images/mobile/m-mine-item-bg22.png")
        no-repeat center center;
      background-size: 100% 100%;
    }

    .item-ac {
      background: url("../../assets/images/mobile/m-mine-item-bg22.png")
        no-repeat center center;
      background-size: 100% 100%;
    }
  }
  .pagination {
    margin: 0.2rem 0;
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .wrapper-box {
      z-index: 2001;

      .wrapper-box-top {
        width: 100%;
        height: 0.03rem;
        background: linear-gradient(
          90deg,
          transparent,
          rgba(72, 84, 201, 0.8),
          transparent
        );
      }

      width: 5rem;
      height: 5.5rem;
      position: relative;
      border: 1px solid #4854c9;
      background-color: #1e2355;
      border-radius: 0.08rem;
      overflow: hidden;
      padding: 0 0.35rem;
      box-sizing: border-box;

      .tit {
        width: 100%;
        font-size: 0.24rem;
        text-align: center;
        line-height: 0.8rem;
      }

      .other {
        width: 100%;
        height: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.2rem;

        p {
          font-size: 0.18rem;
          margin-right: 0.2rem;
        }

        input {
          height: 0.4rem;
          width: 1rem;
          background: rgba(255, 255, 255, 0.06);
          border: 1px solid rgba(255, 255, 255, 0.6);
          color: #e52131;
          border-radius: 0.04rem;
          line-height: 0.4rem;
          text-align: center;
          font-size: 0.18rem;
        }
      }

      .pic {
        width: 2.75rem;
        height: 2.75rem;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.04rem;
        overflow: hidden;

        img {
          max-width: 80%;
          max-height: 80%;
        }
      }

      .btns {
        width: 100%;
        height: 0.5rem;
        display: flex;
        justify-content: center;
        margin-top: 0.2rem;

        span {
          display: block;
          width: 1.65rem;
          height: 0.5rem;
          background: hsla(0, 0%, 100%, 0.06);
          border: 1px solid hsla(0, 0%, 100%, 0.2);
          color: #e4e4e4;
          border-radius: 0.04rem;
          text-align: center;
          line-height: 0.5rem;
          font-size: 0.14rem;
          margin-right: 0.2rem;
          cursor: pointer;
        }

        .btn-two {
          margin-right: 0;
          background: #4854c9;
          border-color: #4854c9;
        }
      }

      .info {
        margin-top: 0.3rem;
        text-align: center;
        font-size: 0.12rem;
        color: rgba(255, 255, 255, 0.53);
      }

      .cancel {
        width: 0.5rem;
        height: 0.5rem;
        position: absolute;
        top: 0;
        right: 0;
        text-align: center;
        line-height: 0.5rem;

        img {
          width: 0.15rem;
          cursor: pointer;
        }
      }
    }
  }
  .listitem {
    overflow: hidden; /* 隐藏超出容器的内容 */
    text-overflow: ellipsis; /* 当文本溢出时显示省略号 */
    white-space: nowrap; /* 防止文本换行 */
    position: relative;
    height: 0.4rem;
    line-height: 0.4rem;
    font-weight: 400;
    font-size: 0.1rem;
    color: #cccccc;
  }
  .showlist {
    position: absolute;
    width: 2.7rem;
    height: 2.7rem;

    overflow-y: scroll;
    .putlist {
      font-weight: 400;
      font-size: 0.12rem;
      color: #ffffff;
      margin: 0.05rem 0;
    }
  }
}
.showbox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .itembox {
    width: 3.35rem;
    border: 0.01rem solid #5d5d9b;
    background: #08081e;
    .title {
      width: 100%;
      height: 0.38rem;
      text-align: center;
      line-height: 0.38rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 0.16rem;
      color: #dfdbff;
      position: relative;
      img {
        position: absolute;
        right: 0.2rem;
        top: 0.1rem;
        width: 0.12rem;
      }
    }
    .line {
      width: 100%;
      border: 0.01rem solid;
      border-image: linear-gradient(
          90deg,
          rgba(93, 93, 155, 0),
          rgba(93, 93, 155, 1),
          rgba(93, 93, 155, 0)
        )
        1 1;
    }
    .content {
      padding: 0.2rem 0;
      margin: 0 auto;
      height: 2.5rem;
      overflow-y: scroll;
      .namesbox {
        width: 2.75rem;
        height: 0.36rem;
        margin: 0 auto;
        text-align: center;
        line-height: 0.36rem;
        font-weight: 400;
        font-size: 0.1rem;
        color: #cccccc;
        background: #08080f;
        border: 0.01rem solid #2f2f5e;
      }
    }
  }
}
</style>
